header {
  //@extend .position-relative;

  h2 {
    @extend .fw-bold, .text-white;
  }

  .header__switch {
    @extend .d-flex, .justify-content-between, .align-items-center, .w-100;
    margin-top: -6rem;
    z-index: 2;

    form {
      @extend .d-flex, .align-items-center;

      .form-switch {
        @extend .ps-0;

        #custom-switch {
          @extend .mx-2, .cursor-pointer;
        }
      }
    }
  }
}

@media only screen and(max-width: 820px) {
  header {
    .header__switch {
      margin-top: -4rem;
    }
  }
}

@media only screen and(max-width: 500px) {
  header {
    .header__switch {
      margin-top: -6rem;
    }
  }
}

