@import "./variables";
@import "./overrides/Boostrap";
@import "../../shared/layout/Header.scss";
@import "../../shared/layout/Footer.scss";
@import "./app/DevJobs.scss";
@import "./app/Profile.scss";

$theme-color: var(--themeColor);

body {
  font-family: "Kumbh Sans", sans-serif;
  background: $theme-color;
}

a {
  @extend .text-decoration-none;
  color: unset;
}

.row {
  @extend .w-100;
}

.dark-theme {
  background: $midnight;
}

.light-theme {
  background: $white;
}

.w-30 {
  width: 30%;
}

.w-md-50 {
  width: 50%;
}

.w-md-25 {
  width: 25%;
}

.cursor-pointer {
  cursor: pointer;
}

.box-shadow {
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.1);
}

.form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
}

.border-right-dark {
  border-right: 1px solid $midnight;
}

.form-check-label {
  @extend .fw-bold, .cursor-pointer;
}

.btn {
  @extend .fw-bold, .p-2;
  border-radius: $default-border-radius;
}

.input-field {
  @extend .p-2, .w-100;
  border: none;

  &:focus-visible {
    outline: none;
    //border-bottom: 1px solid #9DAEC2; // better with border?
  }
}

@media only screen and (max-width: 768px) {
  .w-md-50 {
    width: 100%;
  }
  .w-md-25 {
    width: 100%;
  }
}
