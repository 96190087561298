.main__container {
  @extend .position-relative;

  .main__content {
    .main__searchFields {
      @extend .mt-lg-4,
        .box-shadow,
        .p-md-3,
        .d-flex,
        .align-items-center,
        .justify-content-around;
      height: 5rem;
      border-radius: $default-border-radius;

      .col-md-4.border-md-end {
        border-right: 1px solid #dee2e6;
      }

      label[for="inline-checkbox-dark"] {
        color: $white;
      }
    }
  }

  .main__jobCards {
    @extend .mt-5;

    .main__cardsBody {
      padding: 2.5rem;
      border-radius: $default-border-radius;
    }

    .main__cardsLogo {
      @extend .position-absolute,
        .d-flex,
        .align-items-center,
        .justify-content-center;
      top: -1.5rem;
      left: 2.5rem;
      width: 50px;
      aspect-ratio: 1/1;
      border-radius: $default-border-radius * 1.5;
    }
  }
}

@media only screen and(max-width: 768px) {
  .main__container {
    .main__content {
      .main__searchFields {
        padding: 1rem;
        height: fit-content;

        .col-md-4.border-md-end {
          border-right: none;

          #location-icon {
            padding: 4px;
          }
        }
      }
    }
  }
}

@media only screen and(max-width: 500px) {
  .main__container {
    .main__content {
      margin-top: 10%;
    }
  }
}
