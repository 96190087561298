$theme-colors: (
  "primary": #5964e0,
  "gray": #9daec2,
  "light-violet": #939bf4,
  "dark-blue": #19202d,
  "midnight": #121721,
  "white": #fff,
  "light-gray": #979797,
  "dark-gray": #6e8098,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
