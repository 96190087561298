.company__profile {
  .row {
    @extend .position-relative;
    border-radius: $default-border-radius;
    min-height: 11rem;
    margin-top: -4rem;
  }

  .company__body {
    @extend .box-shadow, .my-5, .p-3, .p-md-5;
    border-radius: $default-border-radius;

    .company__title {
      @extend .d-flex,
        .justify-content-between,
        .align-items-center,
        .flex-column,
        .flex-md-row;
    }

    .company__description {
      @extend .mt-3;

      #custom-ol-numbering {
        counter-reset: item;

        li {
          display: block;

          &:before {
            @extend .fw-bold, .text-primary, .me-1;
            content: counter(item) " ";
            counter-increment: item;
          }
        }
      }
    }
  }
}
